<template>
  <div class="row" id="setting_notes">
    <div class="col-md-12">
      <div class="card form-body card-custom gutter-b example example-compact">
        <div
          class="card-header"
          style="
    position: sticky;
    top: 99px;
    z-index: 9;
    background: white;
"
        >
          <h5 class="card-title">
            Email Template
          </h5>

          <button
            ref="kt_update_note"
            type="reset"
            class="btn btn-success mr-2"
            style="height: 36px;float: right;margin-top: 18px;"
            @click="updateEmailTemplete()"
          >
            Update
          </button>
        </div>
        <div class="card-body">
          <vDataLoader v-if="dataLoading"></vDataLoader>

          <form v-else>
            <h6>Applicant Template</h6>
            <div class="form-group">
              <editor
                api-key="71vg7medn8k1guoqbic2prdchbq0em4dqvhruo5ko352ck6h"
                v-model="emailtemplete"
                :init="config"
              />
            </div>
            <hr />
            <h6>Job Template</h6>
            <div class="form-group">
              <jobeditor
                api-key="71vg7medn8k1guoqbic2prdchbq0em4dqvhruo5ko352ck6h"
                v-model="jobtemplate"
                :init="job_config"
              />
            </div>
            <h6>ATF Email Template</h6>
            <div class="form-group">
              <emailotpeditor
                api-key="71vg7medn8k1guoqbic2prdchbq0em4dqvhruo5ko352ck6h"
                v-model="atfemailtemplate"
                :init="atf_email_config"
              />
            </div>
            <h6>Email OTP Template</h6>
            <div class="form-group">
              <emailotpeditor
                api-key="71vg7medn8k1guoqbic2prdchbq0em4dqvhruo5ko352ck6h"
                v-model="emailotptemplate"
                :init="email_otp_config"
              />
            </div>
            <h6>SMS OTP Template</h6>
            <div class="form-group">
              <smsotpeditor
                api-key="71vg7medn8k1guoqbic2prdchbq0em4dqvhruo5ko352ck6h"
                v-model="smsotptemplate"
                :init="sms_otp_config"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import {
  FETCH_EMAIL_TEMPLETE,
  UPDATE_EMAIL_TEMPLETE
} from "@/core/services/store/actions.type";
import Editor from "@tinymce/tinymce-vue";
import vDataLoader from "@/components/frontend/spinner.vue";

export default {
  components: {
    vDataLoader,
    editor: Editor,
    jobeditor: Editor,
    emailotpeditor: Editor,
    smsotpeditor: Editor
  },
  name: "app",
  data() {
    return {
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Setting",
          route: ""
        },
        {
          id: 3,
          title: "Email Template",
          route: ""
        }
      ],
      editor: ClassicEditor,
      jobeditor: ClassicEditor,
      emailotpeditor: ClassicEditor,
      smsotpeditor: ClassicEditor,

      emailtemplete: "",
      emailotptemplate: "",
      smsotptemplate: "",
      jobtemplate: "",
      atfemailtemplate: "",
      dataLoading: true,

      id: 0,
      config: {
        height: 500,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount"
        ],
        toolbar:
          "undo redo | formatselect | fontsizeselect |bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | code | help",
        fontsize_formats:
          "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
        menubar: "custom",
        menu: {
          custom: {
            title: `<button class="btn btn-success dropdown">Applicant template <i class="menu-icon flaticon2-down"></i></button>`,
            items:
              "appid appicantname applicantphone clientfulladdress sdate showcontact scheduletime servicename findlocation serviceprovider"
          }
        },
        setup: function(editor) {
          editor.ui.registry.addMenuItem("appicantname", {
            text: "Applicant name",
            onAction: function() {
              editor.insertContent("{applicant_full_name}");
            }
          });
          editor.ui.registry.addMenuItem("findlocation", {
            text: "Find location",
            onAction: function() {
              editor.insertContent(`<a href="https://www.google.com/maps/place/{lat},{long}"
                                    style="background-color:#ffbe00; border:1px solid #FFBE00; border-color:#ffbe00; border-radius:0px;
                                    border-width:1px; color:#ffffff; display:inline-block; font-size:14px; font-weight:normal;
                                    letter-spacing:0px; line-height:normal; padding:12px 40px 12px 40px;
                                    text-align:center; text-decoration:none; border-style:solid; font-family:inherit;"
                                    target="_blank">Find
                                    our
                                    location
                                    on
                                    map</a>`);
            }
          });

          editor.ui.registry.addMenuItem("serviceprovider", {
            text: "Service Provider",
            onAction: function() {
              editor.insertContent("{service_provider}");
            }
          });
          editor.ui.registry.addMenuItem("applicantphone", {
            text: "Applicant phone no",
            onAction: function() {
              editor.insertContent("{applicant_phone}");
            }
          });

          editor.ui.registry.addMenuItem("appid", {
            text: "Applicant Id",
            onAction: function() {
              editor.insertContent("{app_id}");
            }
          });

          editor.ui.registry.addMenuItem("clientfulladdress", {
            text: "Location full address",
            onAction: function() {
              editor.insertContent("{client_full_address}");
            }
          });
          editor.ui.registry.addMenuItem("sdate", {
            text: "Schedule date",
            onAction: function() {
              editor.insertContent("{s_date}");
            }
          });
          editor.ui.registry.addMenuItem("showcontact", {
            text: "Location contact",
            onAction: function() {
              editor.insertContent("{client_show_contact}");
            }
          });
          editor.ui.registry.addMenuItem("scheduletime", {
            text: "Schedule time",
            onAction: function() {
              editor.insertContent("{schedule_time}");
            }
          });
          editor.ui.registry.addMenuItem("servicename", {
            text: "Service name",
            onAction: function() {
              editor.insertContent("{service_name}");
            }
          });
        }
      },
      atf_email_config: {
        height: 500,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount"
        ],
        toolbar:
          "undo redo | formatselect | fontsizeselect |bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | code | help",
        fontsize_formats:
          "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
        menubar: "custom",
        menu: {
          custom: {
            title: `<button class="btn btn-success dropdown">ATF template <i class="menu-icon flaticon2-down"></i></button>`,
            items:
              "appicantname clientfulladdress showcontact servicename serviceprovider"
          }
        },
        setup: function(editor) {
          editor.ui.registry.addMenuItem("appicantname", {
            text: "Applicant name",
            onAction: function() {
              editor.insertContent("{applicant_full_name}");
            }
          });

          editor.ui.registry.addMenuItem("serviceprovider", {
            text: "Service Provider",
            onAction: function() {
              editor.insertContent("{service_provider}");
            }
          });

          editor.ui.registry.addMenuItem("clientfulladdress", {
            text: "Location full address",
            onAction: function() {
              editor.insertContent("{client_full_address}");
            }
          });

          editor.ui.registry.addMenuItem("showcontact", {
            text: "Location contact",
            onAction: function() {
              editor.insertContent("{client_show_contact}");
            }
          });

          editor.ui.registry.addMenuItem("servicename", {
            text: "Service name",
            onAction: function() {
              editor.insertContent("{service_name}");
            }
          });
        }
      },
      job_config: {
        height: 500,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount"
        ],
        toolbar:
          "undo redo | formatselect | fontsizeselect |bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | code | help",
        fontsize_formats:
          "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
        menubar: "custom",
        menu: {
          custom: {
            title: `<button class="btn btn-success dropdown">Job template <i class="menu-icon flaticon2-down"></i></button>`,
            items:
              "clientname joblink sdate showcontact scheduletime servicename serviceprovider"
          }
        },
        setup: function(jobeditor) {
          jobeditor.ui.registry.addMenuItem("clientname", {
            text: "Client name",
            onAction: function() {
              jobeditor.insertContent("{client_name}");
            }
          });

          jobeditor.ui.registry.addMenuItem("joblink", {
            text: "Registration Link",
            onAction: function() {
              jobeditor.insertContent(`<a href="{link}"
                                    style="background-color:#ffbe00; border:1px solid #FFBE00; border-color:#ffbe00; border-radius:0px;
                                    border-width:1px; color:#ffffff; display:inline-block; font-size:14px; font-weight:normal;
                                    letter-spacing:0px; line-height:normal; padding:12px 40px 12px 40px;
                                    text-align:center; text-decoration:none; border-style:solid; font-family:inherit;"
                                    target="_blank">Register Here</a>`);
            }
          });
          jobeditor.ui.registry.addMenuItem("sdate", {
            text: "Schedule date",
            onAction: function() {
              jobeditor.insertContent("{s_date}");
            }
          });
          jobeditor.ui.registry.addMenuItem("showcontact", {
            text: "Location contact",
            onAction: function() {
              jobeditor.insertContent("{client_show_contact}");
            }
          });
          jobeditor.ui.registry.addMenuItem("scheduletime", {
            text: "Schedule time",
            onAction: function() {
              jobeditor.insertContent("{schedule_time}");
            }
          });
          jobeditor.ui.registry.addMenuItem("servicename", {
            text: "Service name",
            onAction: function() {
              jobeditor.insertContent("{service_name}");
            }
          });
        }
      },
      email_otp_config: {
        height: 500,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount"
        ],
        toolbar:
          "undo redo | formatselect | fontsizeselect |bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | code | help",
        fontsize_formats:
          "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
        menubar: "custom",
        menu: {
          custom: {
            title: `<button class="btn btn-success dropdown">Email OTP template <i class="menu-icon flaticon2-down"></i></button>`,
            items: "otp locationname serviceprovider"
          }
        },
        setup: function(emailotpeditor) {
          emailotpeditor.ui.registry.addMenuItem("otp", {
            text: "OTP",
            onAction: function() {
              emailotpeditor.insertContent("{otp}");
            }
          });

          emailotpeditor.ui.registry.addMenuItem("locationname", {
            text: "Location Name",
            onAction: function() {
              emailotpeditor.insertContent("{locationname}");
            }
          });
          emailotpeditor.ui.registry.addMenuItem("serviceprovider", {
            text: "Service Provider",
            onAction: function() {
              emailotpeditor.insertContent("{serviceprovider}");
            }
          });
        }
      },
      sms_otp_config: {
        height: 500,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount"
        ],
        toolbar:
          "undo redo | formatselect | fontsizeselect |bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | code | help",
        fontsize_formats:
          "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
        menubar: "custom",
        menu: {
          custom: {
            title: `<button class="btn btn-success dropdown">SMS OTP template <i class="menu-icon flaticon2-down"></i></button>`,
            items: "otp locationname serviceprovider"
          }
        },
        setup: function(smsotpeditor) {
          smsotpeditor.ui.registry.addMenuItem("otp", {
            text: "OTP",
            onAction: function() {
              smsotpeditor.insertContent("{otp}");
            }
          });

          smsotpeditor.ui.registry.addMenuItem("locationname", {
            text: "Location Name",
            onAction: function() {
              smsotpeditor.insertContent("{locationname}");
            }
          });
          smsotpeditor.ui.registry.addMenuItem("serviceprovider", {
            text: "Service Provider",
            onAction: function() {
              smsotpeditor.insertContent("{serviceprovider}");
            }
          });
        }
      }
    };
  },
  created() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.getEmailTemplete();
  },

  methods: {
    getEmailTemplete() {
      this.dataLoading = false;
      this.$store
        .dispatch(FETCH_EMAIL_TEMPLETE)
        .then(res => {
          var data = res.data;
          this.id = data.id ? data.id : 0;

          this.emailtemplete = data.applicant_template;
          this.jobtemplate = data.job_template;
          this.smsotptemplate = data.sms_otp_template;
          this.emailotptemplate = data.email_otp_template;
          this.atfemailtemplate = data.atf_template;
          this.dataLoading = false;
        })
        .catch(() => {
          this.dataLoading = false;
        });
    },
    updateEmailTemplete() {
      const submitButton = this.$refs["kt_update_note"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      var data = {
        form_data: {
          applicant_template: this.emailtemplete,
          job_template: this.jobtemplate,
          sms_otp_template: this.smsotptemplate,
          email_otp_template: this.emailotptemplate,
          atf_template: this.atfemailtemplate
        },
        id: this.id
      };
      this.$store
        .dispatch(UPDATE_EMAIL_TEMPLETE, data)
        .then(res => {
          this.id = res.i_id;
          this.$toastr.s(res.msg);
          this.getEmailTemplete();
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
        })
        .catch(err => {
          this.$toastr.e(err.msg);
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
        });
    }
  }
};
</script>
<style>
.dropdown {
  padding-top: 14% !important;
  padding-left: 1% !important;
  padding-bottom: 12% !important;
  padding-right: -1% !important;
  margin-left: -6% !important;
  width: 111% !important;
}
</style>
